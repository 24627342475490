<template>
  <v-card
    elevation="10"
    height="590"
    class="mx-2"
  >
    <v-toolbar
      dense
      dark
      primary-title
      color="green"
    >
      <v-toolbar-title>Mis Oportunidades</v-toolbar-title>
      <v-spacer />
      <v-btn
        text
        class="white--text"
        dark
        :to="{name: 'oportunidad'}"
      >
        Nueva
        <v-icon color="white">
          mdi-clipboard
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />

    <v-list
      two-line
      class="overflow-y-auto"
      height="540"
    >
      <v-list-item-group
        v-model="selected"
        active-class="teal--text"
      >
        <template v-for="(item, index) in misoportunidades">
          <v-list-item :key="item.idoportunidad">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.asunto" />
                <v-list-item-subtitle
                  class="text--teal"
                  v-text="item.headline"
                />
                <v-list-item-subtitle v-text="item.obs" />
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="item.creada" />

                <v-icon
                  v-if="!active"
                  color="grey lighten-1"
                >
                  mdi-star-outline
                </v-icon>

                <v-icon
                  v-else
                  color="yellow darken-3"
                >
                  mdi-star
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selected: [2],
    items: [],
    misoportunidades: [],
  }),

  created(){
    this.consultar()
  },

  methods: {
      
    consultar(){
      // limpiar
      this.misoportunidades= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http.get("v2/oportunidad.list" ).then(response=>{
        if (response.data.error===null){
          this.misoportunidades = response.data.result
          // console.log(this.misoportunidades)

          // this.misoportunidades.forEach( element => {
          //   element.creada = element.fechastart.substr(5,5)
          //   element.hora = element.fechastart.substr(11,17)
          // })

          // console.log("citas", this.misoportunidades)
        }
          
      }).catch(error=>{
        console.log(error)
      })
    },
      
    ver(item){
      console.log("orden", item.idorden)
      this.verOrden(item.idordenes)
    },
  }
}
</script>